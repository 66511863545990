import "./Banner.css";
import MediaQuery from "react-responsive";
const Banner = () => {
  return (
    <header className="cabecalho">
      <img
        className="cabecalho-logo"
        src="../imagens/logo_djn.png"
        alt="Logo cabecalho"
      />
      <div className="cabecalho-container__esquerda">
        <MediaQuery minWidth={429}>
          <p className="texto__esquerda">
            Com nossos <br /> serviços, o <span>Boss</span> <br /> é você!
          </p>
        </MediaQuery>
        <MediaQuery maxWidth={428}>
          <p className="texto__esquerda">
            Com nossos <br /> serviços, o <br /> <span>Boss</span> é você!
          </p>
        </MediaQuery>
      </div>
      <div className="cabecalho-container__direita">
        <p className="texto__direita">
          Mergulhe na aventura e <br /> deixe o esforço conosco.
        </p>
      </div>
    </header>
  );
};

export default Banner;
