import './BotaoOrcamento.css'

const BotaoOrcamento = () => {
    const direcionaContatos = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'auto'
        })
    }

    return (
        <button onClick={direcionaContatos} className='botao-orcamento'>Solicite seu Orçamento</button>
    )
}

export default BotaoOrcamento;