import "./Feedback.css";
import Carrossel from "../Carrossel";

export const Feedback = () => {
  return (
    <section className="feedback">
      <h1 className="feedback-titulo">Alguns dos nossos resultados</h1>
      <Carrossel />
    </section>
  );
};

export default Feedback;
