import Rodape from '../Rodape';
import './Contato.css'

const Contato = () => {
    return (
        <section className='contato'>
            <h3>Clique e fale conosco<br/> agora mesmo</h3>
            <div className='container-logo'><img className='logo-djn' src='/imagens/logo_contato.png' alt='Logo DJN'></img></div>
            <a href='http://wa.link/djNStore' className='link-whatsapp'><img className='img-whatsapp' src='/imagens/botao_zap.png' alt='Botão Whatsapp'></img></a>
            <div className='contato-direita'>
                <p>Outras formas <br/> de contato</p>
                <a href='https://www.instagram.com/djnstoreoficial ' className='link-insta'><img className='img-insta' src='/imagens/botao_insta.png' alt='Botão Instagram'></img></a>
                <a href='https://discord.com/invite/djnstore' className='link-discord'><img className='img-discord' src='/imagens/botao_discord.png' alt='Botão Discord'></img></a>
            </div>
            <Rodape />
        </section>
    )
}

export default Contato;