import BotaoOrcamento from "../BotaoOrcamento";
import { EffectCoverflow } from "swiper/modules";
import ServicosTextos from "../ServicosTextos";
import MediaQuery from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";

import "./Servicos.css";

const listaServicos = [
  {
    titulo: "Venda de Itens:",
    descricao:
      "Vendemos os itens que você precisa para que você possa evoluir sem dor de cabeça.",
    lista: ["Gold.", "Material de Boss.", "Itens específicos."],
    imagem: "imagens/scaling_servicos.png"
  },
  {
    titulo: "Scaling / Progressão:",
    descricao:
      "Evite horas massantes de grind e foque no que realmente te interessa e diverte. Conte conosco para o trabalho pesado!",
    lista: [
      "Power Level 1 - 100 (Personalizado).",
      "Up de glifo 1 - 21 (Personalizado).",
      "Altares de Lilith.",
    ],
    imagem: "imagens/itens_servicos.png"
  },
  {
    titulo: "Outros Seviços:",
    descricao:
      "Conte com serviços personalizados para evoluir sua experiência in game.",
    lista: [
      "Kill Boss.",
      "Fosso (materiais Masterworking).",
      "Consultoria de conta e personagem.",
    ],
    imagem: "imagens/outros_servicos.png"
  },
];

const Servicos = (props) => {
  return (
    <div className="servicos">
      <MediaQuery minWidth={1080}>
        {listaServicos.map((servico) => (
          <ServicosTextos data={servico} />
        ))}
      </MediaQuery>
      <MediaQuery maxWidth={428}>
        <Swiper
        modules={[EffectCoverflow]}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 300,
          modifier: 1,
          slideShadows: false,
        }}
        initialSlide={2}
        loop={true}
        centeredSlides={true}
        spaceBetween={60}
        effect="coverflow"
        slidesPerView={1.3}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}>
          {listaServicos.map((servico) => (
            <SwiperSlide>
              <ServicosTextos data={servico} />
            </SwiperSlide>
          ))}
        </Swiper>
      </MediaQuery>
      <BotaoOrcamento />
    </div>
  );
};

export default Servicos;
