import MediaQuery from "react-responsive";
import "./Sobre.css";

const Sobre = () => {
  return (
    <section className="sobre">
      <h3 className="sobre-titulo">Sobre Nós</h3>
      <MediaQuery minWidth={1080}>
        <p className="sobre-descricao">
          Na DJN Store, oferecemos serviços de RMT de alta qualidade e <br />
          segurança para MMOs, garantindo que você aproveite ao máximo <br />
          seu jogo. Nossa equipe especializada é dedicada a entregar <br />
          resultados excepcionais, seja em boosts, builds ou venda de itens.
        </p>
      </MediaQuery>
      <MediaQuery maxWidth={428}>
        <p className="sobre-descricao">
          Na DJN Store, oferecemos serviços de RMT de alta<br /> 
          qualidade e segurança para MMOs, garantindo que<br /> 
          você aproveite ao máximo seu jogo. Nossa equipe<br />
          especializada é dedicada a entregar resultados<br />
          excepcionais, seja em boosts, builds ou venda de<br /> 
          itens.
        </p>
      </MediaQuery>
      <h4 className="sobre-rodape">Revolucionamos experiências de jogo.</h4>
    </section>
  );
};

export default Sobre;
