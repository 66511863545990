import Banner from "./components/Banner";
import Servicos from "./components/Servicos";
import Sobre from "./components/Sobre";
import Contato from "./components/Contato";

import { register } from "swiper/element/bundle";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Feedback from "./components/Feedback";
register();

function App() {
  return (
    <div className="App">
      <Banner />
      <Servicos />
      <Sobre />
      <Feedback />
      <Contato />
    </div>
  );
}

export default App;
