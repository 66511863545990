import MediaQuery from "react-responsive";
import "./ServicosTextos.css";

const ServicosTextos = (props) => {
  return (
    <div className="servicos-textos">
      <MediaQuery minWidth={1080}>
        <p className="servicos-titulo">{props.data.titulo}</p>
        <p className="servicos-descricao">{props.data.descricao}</p>
        <ul>
          {props.data.lista.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      </MediaQuery>
      <MediaQuery maxWidth={1080}>
        <img src={`${props.data.imagem}`} alt="Imagem de serviços"/>
      </MediaQuery>
    </div>
  );
};

export default ServicosTextos;
