import { useRef, useEffect } from "react";
import { EffectCoverflow } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Carrossel.css";
import MediaQuery from "react-responsive";

const listaFeedbacks = [
  {
    id: 1,
    feedbackImagem: "imagens/ogro_feedback.png",
  },
  {
    id: 2,
    feedbackImagem: "imagens/sophia_feedback.png",
  },
  {
    id: 3,
    feedbackImagem: "imagens/thanos_feedback.png",
  },
  {
    id: 4,
    feedbackImagem: "imagens/kasser_feedback.png",
  },
  {
    id: 5,
    feedbackImagem: "imagens/messias_feedback.png",
  },
  {
    id: 6,
    feedbackImagem: "imagens/fred_feedback.png",
  },
];

export const Carrossel = () => {
  useEffect(() => {
    // listen for Swiper events using addEventListener
  }, []);

  return (
    <div>
      <MediaQuery minWidth={1080}>
        <Swiper
          modules={[EffectCoverflow]}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 300,
            modifier: 1,
            slideShadows: false,
          }}
          centeredSlides={true}
          spaceBetween={220}
          effect="coverflow"
          slidesPerView={2}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          loop="true"
        >
          {listaFeedbacks.map((feedback) => (
            <SwiperSlide>
              <img
                key={feedback.id}
                className="feedback-imagem"
                src={feedback.feedbackImagem}
                alt="Feedback de usuário"
              ></img>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="container-botoes">
          <div className="botoes-swiper">
            <div className="swiper-button-prev">
              <img
                className="feedback-imagem"
                src="/imagens/botao_anterior.png"
                alt="Feedback de usuário"
              ></img>
            </div>
            <div className="swiper-button-next">
              <img
                className="feedback-imagem"
                src="/imagens/botao_proximo.png"
                alt="Feedback de usuário"
              ></img>
            </div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={428}>
      <Swiper
          modules={[EffectCoverflow]}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 300,
          modifier: 1,
          slideShadows: false,
        }}
        initialSlide={2}
        loop={true}
        centeredSlides={true}
        spaceBetween={40}
        effect="coverflow"
        slidesPerView={1.3}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          }}
        >
          {listaFeedbacks.map((feedback) => (
            <SwiperSlide>
              <img
                key={feedback.id}
                className="feedback-imagem"
                src={feedback.feedbackImagem}
                alt="Feedback de usuário"
              ></img>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="container-botoes">
          <div className="botoes-swiper">
            <div className="swiper-button-prev">
              <img
                className="feedback-imagem"
                src="/imagens/botao_anterior.png"
                alt="Feedback de usuário"
              ></img>
            </div>
            <div className="swiper-button-next">
              <img
                className="feedback-imagem"
                src="/imagens/botao_proximo.png"
                alt="Feedback de usuário"
              ></img>
            </div>
          </div>
        </div>
      </MediaQuery>
    </div>
  );
};

export default Carrossel;
